<template>
  <div>
    <validation-observer ref="formSortiesFrontieresTerrestres">
      <!-- head -->
      <div>
        <!-- <b-row> {{form}}</b-row> -->
        <b-card>
          <b-card-header>
            <b-row
              no-gutters
              class="w-100"
            >
              <b-col md="12">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-popover.hover.focus="
                        '⚪️ La fiche de renseignement à la sortie des frontières terrestres est éditable par les agents aux postes frontaliers. ⚪️ Elle permet de collecter les données sur les voyageurs et leur séjours.'
                      "
                      size="lg"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Information"
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>

              <b-col md="2">
                <b-card-img
                  :src="require('@/assets/images/illustration/frontiere_0.jpg')"
                  alt="Image"
                  class="rounded-0 my-2"
                />
              </b-col>
              <b-col
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <!-- <h1 class="m-auto text-center">FICHE STATISTIQUE</h1> -->
                  <br>
                  <h4 class="m-auto text-center">
                    FICHE DE RENSEIGNEMENT AUX FRONTIERES TERRESTRES (SORTIE)
                  </h4>
                </div>
              </b-col>
              <h5 class="d-flex justify-content-end mt-2">
                Tout les champs avec la mention<RequiredStarComponent /> sont requis</h5>
            </b-row>
          </b-card-header>
          <b-row>
            <!-- body -->
            <b-col
              lg="12"
              class="pb-2"
            >
              <div>
                <b-card>
                  <b-card-body>
                    <b-row
                      v-if="false"
                      class="mb-1"
                    >
                      <b-col md="12">
                        <b-form-group>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div class="label">
                              Commissariat de police
                              <small>(Réservé à l’agent de police)</small>
                            </div>
                            <b-form-input
                              v-model="form.commissariat"
                              type="text"
                              placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Date sortie -->
                      <b-col md="6">
                        <b-form-group>
                          <label
                            for=""
                            class="col-form-label col-form-label-md"
                          >Date de sortie du voyageur <RequiredStarComponent class="mr-2" /></label>

                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|beforeOrToday"
                          >
                            <b-form-input
                              v-model="form.date"
                              lang="fr-CA"
                              type="date"
                              placeholder="Veuillez entrer la date à laquelle vous êtes entré"
                              class="form-control"
                              :max="today"
                            />
                            <!-- <flat-pickr
                              v-model="form.date"
                              class="form-control"
                              placeholder="Date"
                            /> -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- pays residence -->
                      <b-col md="6">
                        <b-form-group>
                          <label
                            for=""
                            class="col-form-label col-form-label-md"
                          >Pays de résidence<RequiredStarComponent class="mr-2" /></label>

                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <SelectCountriesList
                              v-model="form.paysResidence"
                              :input-value="form.paysResidence"
                              :placeholder="'Sélectionnez le pays de résidence'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Nationalite -->
                      <b-col md="6">
                        <b-form-group>
                          <label
                            for=""
                            class="col-form-label col-form-label-md"
                          >Nationalité <RequiredStarComponent class="mr-2" /></label>

                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <SelectCountriesList
                              v-model="form.nationalite"
                              :input-value="form.nationalite"
                              :placeholder="'Sélectionnez le pays de votre nationalité'"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- pays destination -->
                      <b-col md="6">
                        <b-form-group

                          label-class="regular-label"
                        >
                          <label
                            for=""
                            class="col-form-label col-form-label-md"
                          >Pays de destination<RequiredStarComponent class="mr-2" /></label>

                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <SelectCountriesList
                              v-model="form.paysDestination"
                              :input-value="form.paysDestination"
                              :placeholder="'Sélectionnez le pays de destination'"
                              :remove-countries="[&quot;Bénin&quot;]"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- mode hebergement benin -->
                      <b-col
                        v-if="false"
                        md="12"
                      >
                        <b-form-group />
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Mode d'hébergement au benin<RequiredStarComponent class="mr-2" /></label>

                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="form.modeHebergementBenin.radio"
                          >
                            <b-form-radio
                              v-for="(item, index) in modesHebergement"
                              :key="'modeHebergementBenin' + index"
                              name="modeHebergementBenin"
                              :value="item"
                            >
                              {{ item.text }}
                              <validation-provider
                                v-if="
                                  item.code &&
                                    form.modeHebergementBenin.radio &&
                                    form.modeHebergementBenin.radio.code ===
                                    item.code
                                "
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model="form.modeHebergementBenin.input"
                                  class="d-inline"
                                  type="text"
                                  placeholder="Veuillez préciser"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-radio>
                          </b-form-radio-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- SEJOUR A L’ETRANGER POUR LES RESIDENTS DU BÉNIN -->
                      <b-col
                        v-if="isBeninResident(form.paysResidence)"
                        md="12"
                      >
                        <div>
                          SÉJOUR A L’ÉTRANGER POUR LES RÉSIDENTS DU BÉNIN
                        </div>
                        <b-row class="ml-2">
                          <b-col md="12">
                            <b-form-group>
                              <label
                                for=""
                                class="col-form-label col-form-label-md"
                              >Durée de séjour à l'étranger<RequiredStarComponent class="mr-2" /></label>

                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    form.sejourEtranger.dureeSejour
                                  "
                                  v-digits-only
                                  placeholder="Durée de séjour à l'étranger"
                                  type="number"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>

                            <!-- <b-form-group
                              label-cols-lg="12"
                              label="Durée de séjour à l'étranger"
                            >

                              <div class="d-flex align-items-center gap-10">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  class="d-inline-block"
                                >
                                  <b-form-input
                                    v-model="
                                      dureeSejourMois
                                    "
                                    type="number"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <div class="d-inline-block">
                                  mois
                                </div>
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  class="d-inline-block"
                                >
                                  <b-form-input
                                    v-model="
                                      dureeSejourJours
                                    "
                                    type="number"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <div class="d-inline-block">
                                  jours
                                </div>
                              </div>

                            </b-form-group> -->
                          </b-col>
                          <b-col md="12">
                            <b-form-group>
                              <label
                                for=""
                                class="col-form-label col-form-label-md"
                              >Motif principal du voyage à l'étranger<RequiredStarComponent class="mr-2" /></label>

                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="
                                    form.sejourEtranger.motifVoyage.radio
                                  "
                                >
                                  <b-form-radio
                                    v-for="(
                                      item, index
                                    ) in motifPrincipalVoyage"
                                    :key="
                                      'motifPrincipalVoyageEtranger' + index
                                    "
                                    name="motifPrincipalVoyageEtranger"
                                    :value="item"
                                  >
                                    {{ item.text }}
                                    <validation-provider
                                      v-if="
                                        item.code &&
                                          form.sejourEtranger.motifVoyage.radio &&
                                          form.sejourEtranger.motifVoyage.radio
                                            .code === item.code
                                      "
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="
                                          form.sejourEtranger.motifVoyage.input
                                        "
                                        class="d-inline"
                                        type="text"
                                        placeholder="Veuillez préciser"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-radio>
                                </b-form-radio-group>

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group>
                              <label
                                for=""
                                class="col-form-label col-form-label-md"
                              >Mode d'hébergement à l'étranger<RequiredStarComponent class="mr-2" /></label>

                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="
                                    form.sejourEtranger.modeHebergement.radio
                                  "
                                  disabled-field="disabled"
                                >
                                  <b-form-radio
                                    v-for="(item, index) in modesHebergement"
                                    :key="'modesHebergementEtranger' + index"
                                    name="modesHebergementEtranger"
                                    :value="item"
                                  >
                                    {{ item.text }}
                                    <validation-provider
                                      v-if="
                                        item.code &&
                                          form.sejourEtranger.modeHebergement
                                            .radio &&
                                          form.sejourEtranger.modeHebergement
                                            .radio.code === item.code
                                      "
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="
                                          form.sejourEtranger.modeHebergement
                                            .input
                                        "
                                        class="d-inline"
                                        type="text"
                                        placeholder="Veuillez préciser"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-radio>
                                </b-form-radio-group>

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>

                      <!-- SEJOUR AU BÉNIN POUR LES NON-RESIDENTS DU BÉNIN (à remplir seulement par les non- résidents du Bénin) -->
                      <b-col
                        v-if="!isBeninResident(form.paysResidence)"
                        md="12"
                      >
                        <div class="custom-label">
                          SÉJOUR AU BÉNIN POUR LES NON-RÉSIDENTS DU BÉNIN
                        </div>
                        <b-row class="ml-2">
                          <b-col md="12">
                            <b-form-group>
                              <label
                                for=""
                                class="col-form-label col-form-label-md"
                              >Durée de séjour au Bénin<RequiredStarComponent class="mr-2" /></label>

                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="form.sejourBenin.dureeSejour"
                                  v-digits-only
                                  placeholder="Durée de séjour au Bénin"
                                  type="number"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>

                            <!-- <b-form-group
                              label-cols-lg="12"
                              label-class="regular-label"
                              label="DUREE DE SEJOUR AU BÉNIN (Jours-Mois)"
                            >
                              <div class="d-flex align-items-center gap-10">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  class="d-inline-block"
                                >
                                  <b-form-input
                                    v-model.number="
                                      dureeSejourJours
                                    "
                                    type="number"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <div class="d-inline-block">
                                  jours
                                </div>
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  class="d-inline-block"
                                >
                                  <b-form-input
                                    v-model.number="
                                      dureeSejourMois
                                    "
                                    type="number"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <div class="d-inline-block">
                                  mois
                                </div>
                              </div>
                            </b-form-group> -->
                          </b-col>
                          <b-col
                            v-if="form.sejourBenin"
                            md="10"
                          >
                            <b-form-group

                              label-class="regular-label"
                            >
                              <label
                                for=""
                                class="col-form-label col-form-label-md"
                              >MOTIF PRINCIPAL DU VOYAGE AU BÉNIN<RequiredStarComponent class="mr-2" /></label>

                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="form.sejourBenin.motifVoyage.radio"
                                >
                                  <b-form-radio
                                    v-for="(
                                      item, index
                                    ) in motifPrincipalVoyage"
                                    :key="'motifPrincipalVoyageBenin' + index"
                                    name="motifPrincipalVoyageBenin"
                                    :value="item"
                                  >
                                    {{ item.text }}
                                    <validation-provider
                                      v-if="
                                        item.code &&
                                          form.sejourBenin.motifVoyage.radio &&
                                          form.sejourBenin.motifVoyage.radio
                                            .code === item.code
                                      "
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="
                                          form.sejourBenin.motifVoyage.input
                                        "
                                        class="d-inline"
                                        type="text"
                                        placeholder="Veuillez préciser"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-radio>
                                </b-form-radio-group>

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group
                              label-cols-lg="12"
                              label-class="regular-label"
                              label=""
                            >
                              <label
                                for=""
                                class="col-form-label col-form-label-md"
                              >Mode d’hébergement au Bénin<RequiredStarComponent class="mr-2" /></label>

                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="
                                    form.sejourBenin.modeHebergement.radio
                                  "
                                >
                                  <b-form-radio
                                    v-for="(item, index) in modesHebergement"
                                    :key="'modesHebergementBenin' + index"
                                    name="modesHebergementBenin"
                                    :value="item"
                                  >
                                    {{ item.text }}
                                    <validation-provider
                                      v-if="
                                        item.code &&
                                          form.sejourBenin.modeHebergement
                                            .radio &&
                                          form.sejourBenin.modeHebergement.radio
                                            .code === item.code
                                      "
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="
                                          form.sejourBenin.modeHebergement.input
                                        "
                                        class="d-inline"
                                        type="text"
                                        placeholder="Veuillez préciser"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-radio>
                                </b-form-radio-group>

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- CATEGORIE SOCIO-PROfESSIONNELLE/Socio-professional category  -->
                      <b-col md="12">
                        <b-form-group>
                          <label
                            for=""
                            class="col-form-label col-form-label-md"
                          >Catégorie socio-professionnelle<RequiredStarComponent class="mr-2" /></label>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="form.categorieSocioPro.radio"
                              disabled-field="disabled"
                              class="ml-2"
                            >
                              <b-form-radio
                                v-for="(item, index) in categorieSocioProList"
                                :key="'categorieSocioPro' + index"
                                name="categorieSocioPro"
                                :value="item"
                              >
                                {{ item.text }}
                                <validation-provider
                                  v-if="
                                    item.code &&
                                      form.categorieSocioPro.radio &&
                                      form.categorieSocioPro.radio.code ===
                                      item.code
                                  "
                                  v-slot="{ errors }"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="form.categorieSocioPro.input"
                                    class="d-inline"
                                    type="text"
                                    placeholder="Veuillez préciser la catégorie socio-professionnelle"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-radio>
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- age -->
                      <b-col md="6">
                        <b-form-group>
                          <label
                            for=""
                            class="col-form-label col-form-label-md"
                          >Age du voyageur<RequiredStarComponent class="mr-2" /></label>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="between:10,150"
                          >
                            <b-form-input
                              v-model.number="form.age"
                              v-digits-only
                              type="number"
                              placeholder="Veuillez saisir l'âge du voyageur"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- sexe -->
                      <b-col lg="6">
                        <b-form-group>
                          <label
                            for=""
                            class="col-form-label col-form-label-md"
                          >Sexe du voyageur<RequiredStarComponent class="mr-2" /></label>
                          <validation-provider
                            v-slot="{ errors }"
                            rules=""
                          >
                            <b-form-radio-group
                              v-model="form.sexe"
                              :options="sexeList"
                              class="ml-1"
                              value-field="value"
                              text-field="text"
                              disabled-field="disabled"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- lastname -->
                      <b-col md="6">
                        <b-form-group
                          label-cols-md="12"
                          label="Nom"
                        >
                          <b-form-input
                            v-model="form.lastname"
                            v-name
                            type="text"
                            placeholder="Nom du voyageur"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- firstname -->
                      <b-col md="6">
                        <b-form-group
                          label-cols-lg="12"
                          label="Prénom(s)"
                        >
                          <b-form-input
                            v-model="form.firstname"
                            v-name
                            type="text"
                            placeholder="Prénom(s) du voyageur"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- maidenname -->
                      <b-col
                        v-if="form.sexe === 'Féminin'"
                        md="12"
                      >
                        <b-form-group
                          label-cols-lg="12"
                          label-class="regular-label"
                          label="NOM DE JEUNE FILLE"
                        >
                          <b-form-input
                            v-model="form.maidenname"
                            type="text"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>

              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="col-xl-2"
                  @click="sendToPreview()"
                >
                Aperçu avant enregistrement
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </validation-observer>
    <!-- <tips-modal-create-from-last-record :canShow="showCreateFromLastModal"></tips-modal-create-from-last-record> -->
    <!-- <TipsModalCreateFromLastRecord /> -->
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  // BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  // BForm,
  // BCardText,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import localstorageService from "@/services/localstorage/localstorage.service";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
import SelectCountriesList from "@/components/SelectCountriesList.vue";
import RequiredStarComponent from "@/components/RequiredStarComponent.vue";

// imports for validation
// const store = require('store')
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BFormRadio,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    flatPickr,
    // BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    // BCardText,
    // BForm,
    BInputGroupPrepend,
    BInputGroup,
    VBPopover,
    ContentLoader,
    TipsModalCreateFromLastRecord,
    SelectCountriesList,
    RequiredStarComponent,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  mixins: [heightTransition], //
  props: {
    etablissementDetails: null,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: "formstatagence",
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      typesEts: [
        { text: "Agence de voyage", value: "Agence de voyage" },
        { text: "Tour Operator", value: "Tour Operator" },
        { text: "Agence réceptive", value: "Agence réceptive" },
      ],
      sexeList: [
        { text: "Masculin", value: "Masculin" },
        { text: "Féminin", value: "Féminin" },
      ],
      categorieSocioProList: [
        { text: "Libéral/indépendant", value: "Libéral/indépendant" },
        { text: "Employé/salarié", value: "Employé/salarié" },
        { text: "Retraité", value: "Retraité" },
        {
          text: "Autres (A préciser)",
          value: "Autres",
          code: "autres_categorie_socio_pro",
          hasManualInput: true,
        },
      ],
      modesHebergement: [
        { text: "Hôtel et assimilés ", value: "Hôtel et assimilés " },
        { text: "Famille/Amis ", value: "Famille/Amis" },
        {
          text: "Autres",
          value: "Autres",
          code: "autres_mode",
          hasManualInput: true,
        },
      ],
      motifPrincipalVoyage: [
        {
          text: "Affaires et motifs professionnels",
          value: "Affaires et motifs professionnels",
        },
        {
          text: "Vacances, loisirs et détente",
          value: "Vacances, loisirs et détente",
        },
        {
          text: "Visites à des parents ou des amis",
          value: "Visites à des parents ou des amis",
        },
        { text: "Éducation et formation", value: "Éducation et formation" },
        { text: "Santé et soins médicaux", value: "Santé et soins médicaux" },
        { text: "Religion ou pèlerinage", value: "Religion ou pèlerinage" },
        { text: "Achats", value: "Achats" },
        { text: "Transit", value: "Transit" },
        {
          text: "Autres (A préciser)",
          value: "Autres",
          code: "autres_motif_voyage",
          hasManualInput: true,
        },
      ],
      autreModeHebergementBenin: "",
      autreModeHebergementEtranger: "",
      autreMotifVoyage: "",

      dureeSejourJours: null,
      dureeSejourMois: null,
      form: {
        age: "",
        commissariat: "",
        date: "",
        lastname: "",
        firstname: "",
        maidenname: "",
        sexe: "",
        nationalite: "",
        paysResidence: "",
        paysDestination: "",
        sejourEtranger: {
          // dureeSejour: { mois: '', jours: '' },
          dureeSejour: null,
          motifVoyage: { input: null, radio: null },
          modeHebergement: { input: null, radio: null },
        },
        sejourBenin: {
          // dureeSejour: { mois: '', jours: '' },
          dureeSejour: null,
          motifVoyage: { input: null, radio: null },
          modeHebergement: { input: null, radio: null },
        },
        categorieSocioPro: { input: null, radio: null },
      },
      today: moment().format("YYYY-MM-DD"),

      // validOutput: {
      //   age: '80',
      //   commissariat: '0202112638057',
      //   date: '2022-09-14',
      //   lastname: 'test',
      //   firstname: 'test',
      //   maidenname: '',
      //   sexe: 'Masculin',
      //   nationalite: {
      //     name: 'American Samoa',
      //     code: 'AS',
      //   },
      //   paysResidence: {
      //     name: 'Åland Islands',
      //     code: 'AX',
      //   },
      //   paysDestination: {
      //     name: 'Albania',
      //     code: 'AL',
      //   },
      //   sejourEtranger: {
      //     dureeSejour: '',
      //   },
      //   sejourBenin: {
      //     dureeSejour: '89',
      //     motifVoyage: 'test',
      //     modeHebergement: 'Famille/Amis',
      //   },
      //   categorieSocioPro: 'Retraité',
      // },

      agrements: [
        { etablissement: "Les Hirondelles", reference: "hg7h878jhjKJ" },
      ],
      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        console.log("val", val, this.formInput);
        const userId = localstorageService.getUserId();
        const etablissementDetails = this.etablissementDetails || {};

        if (this.formInput) {
          this.isCheckingForLastForm = false;
          this.isFillingForm = true;
          console.log(this.formInput);
          this.fillFormWithProvidedInput(this.formInput);
          setTimeout(() => {
            this.isFillingForm = false;
          }, 1000);
        } else {
          // this.fillFormWithProvidedInput(this.validOutput);
          setTimeout(() => {
            this.isCheckingForLastForm = false;
          }, 1000);
        }
      },
    },
    dureeSejourJours: {
      handler(val, old) {
        const isBeninResident = this.isBeninResident(this.form.paysResidence);
        console.log(this.dureeSejourMois, this.dureeSejourJours);
        // calculate nombreOfDays
        const sejourInfosKey = isBeninResident
          ? "sejourEtranger"
          : "sejourBenin";
        const sejourInfos = this.form[sejourInfosKey];
        const { dureeSejour } = sejourInfos;

        this.form[sejourInfosKey].dureeSejour = {
          jours: this.dureeSejourJours,
          mois: this.dureeSejourMois,
        };

        // this.form[isBeninResident ? 'sejourEtranger' : 'sejourBenin'].dureeSejour = ''
      },
    },
    dureeSejourMois: {
      handler(val, old) {
        const isBeninResident = this.isBeninResident(this.form.paysResidence);
        // calculate nombreOfDays
        const sejourInfosKey = isBeninResident
          ? "sejourEtranger"
          : "sejourBenin";
        const sejourInfos = this.form[sejourInfosKey];
        const { dureeSejour } = sejourInfos;

        this.form[sejourInfosKey].dureeSejour = {
          jours: this.dureeSejourJours,
          mois: this.dureeSejourMois,
        };
        console.log(this.form);
        // this.form[isBeninResident ? 'sejourEtranger' : 'sejourBenin'].dureeSejour = ''
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    // this.annees = utilsService.getListYears();
    // this.mois = utilsService.getListMonths();
    this.annees = null;
    this.mois = null;
    this.pays = utilsService.getListCountries();
    this.getListTypesEtablissement();
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaire", {
      getLastFormCreatedByUser: "getLastFormCreatedByUser",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {}),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    isBeninResident(country) {
      if (country && country.name) return country.name === "Bénin";
      return country && country === "Bénin";
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          console.log("typeets", res);
          // this.typesEts = res.data.typeetablissements || [];
        })
        .catch((err) => {});
    },
    showModal() {
      this.$refs[this.tipsModalRef].show();
    },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithProvidedInput(inputDatas) {
      const { sejourBenin, sejourEtranger, categorieSocioPro } = inputDatas;

      const reverseSearchItem = (item, searchValueIn, itemType) => {
        if (!item) return { input: null, radio: null };
        const foundItem = this[searchValueIn].find((e) => e.value === item);
        if (foundItem) return { radio: foundItem, input: null };
        const manualInputItem = this[searchValueIn].find(
          (e) => e.code && e.code.includes("autre")
        );
        return { radio: manualInputItem, input: item };
      };
      this.form = {
        ...inputDatas,
        sejourBenin: {
          ...sejourBenin,
          motifVoyage: reverseSearchItem(
            sejourBenin.motifVoyage,
            "motifPrincipalVoyage"
          ),
          modeHebergement: reverseSearchItem(
            sejourBenin.modeHebergement,
            "modesHebergement"
          ),
        },
        sejourEtranger: {
          ...sejourEtranger,
          motifVoyage: reverseSearchItem(
            sejourEtranger.motifVoyage,
            "motifPrincipalVoyage"
          ),
          modeHebergement: reverseSearchItem(
            sejourEtranger.modeHebergement,
            "modesHebergement"
          ),
        },
        categorieSocioPro: reverseSearchItem(
          categorieSocioPro,
          "categorieSocioProList"
        ),
      };
    },
    sendToPreview() {
      const { userData } = utilsService.currentUserUtils();
      const { commissariatAssociated } = userData;
      this.$refs.formSortiesFrontieresTerrestres
        .validate()
        .then(async (success) => {
          if (success) {
            const { sejourBenin, sejourEtranger, categorieSocioPro } =
              this.form;

            // calculate nombreOfDays ----------------------------
            // const isBeninResident = this.isBeninResident(this.form.paysResidence)
            // const sejourInfosKey = isBeninResident
            //   ? 'sejourEtranger'
            //   : 'sejourBenin'
            // const sejourInfos = this.form[sejourInfosKey]
            // const { dureeSejour } = sejourInfos
            // const { mois: dureeSejourMois, jours: dureeSejourJours } = dureeSejour
            // if (this.form.date) {
            //   const date = this.form.date.split('-').map(e => parseInt(e))
            //   date[1] -= 1
            //   const futurDate = moment(date)
            //   if (dureeSejourMois > 0) {
            //     futurDate.add(dureeSejourMois, 'month')
            //   }
            //   if (dureeSejourJours > 0) {
            //     futurDate.add(dureeSejourJours, 'days')
            //   }
            //   const diffNbreJours = futurDate.diff(moment(date), 'days')
            //   if (this.form[sejourInfosKey].dureeSejour) this.form[sejourInfosKey].dureeSejour.result = diffNbreJours
            // }
            // this.form[isBeninResident ? 'sejourBenin' : 'sejourEtranger'].dureeSejour = ''
            // end calculate nombreOfDays -------------------------

            const isNotNull = (val) => val && val.trim() !== "";
            const getValueFromInput = (inputField, fieldType) => {
              let result = "";
              if (fieldType === "radio") {
                result =
                  inputField.radio && inputField.radio.hasManualInput
                    ? inputField.input
                    : inputField.radio?.value;
              } else {
                result = isNotNull(inputField.input)
                  ? inputField.input
                  : inputField.value;
              }
              return result;
            };

            // clearing data
            const form = {
              ...this.form,
              commissariat: (commissariatAssociated || {})._id,
              sejourBenin: {
                ...sejourBenin,
                motifVoyage: getValueFromInput(
                  sejourBenin.motifVoyage,
                  "radio"
                ),
                modeHebergement: getValueFromInput(
                  sejourBenin.modeHebergement,
                  "radio"
                ),
              },
              sejourEtranger: {
                ...sejourEtranger,
                motifVoyage: getValueFromInput(
                  sejourEtranger.motifVoyage,
                  "radio"
                ),
                modeHebergement: getValueFromInput(
                  sejourEtranger.modeHebergement,
                  "radio"
                ),
              },
              categorieSocioPro: getValueFromInput(categorieSocioPro, "radio"),
            };

            // if (form.qrcode) delete form.qrcode;
            // if (form._id) delete form._id;

            console.log(
              "------------------------- finale data form: ===> ",
              form
            );
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: form },
            });
          } else {
            const errorFirst = this.$el.querySelector(".is-invalid");
            console.log(errorFirst);
            if (errorFirst) {
              // const errorFirstInput = errorFirst.querySelector('label')
              errorFirst.scrollIntoView({ behavior: "instant" });
              errorFirst.focus();
            }
          }
        });
    },
    // loadPays() {
    //   this.pays = [{ name: "Bénin" }, { name: "Togo" }, { name: "Niger" }];
    // },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-flatpicker.scss";
.regular-label {
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}
.capitalize {
  &::first-letter {
    text-transform: uppercase;
  }
}
</style>
